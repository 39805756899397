import store from '../../store'
import axios from 'axios'
import constants from '../../constant'
import qs from 'qs';
import { isEmpty } from 'lodash';

let cancelToken = axios.CancelToken
let cancel

function transformData(data, lang) {
    return data.map(mission => {
        const theme = mission.theme ? mission.theme.name : null;

        return {
            ...mission,
            only_teams: mission.team.only_teams,
            allow_team_volunteering: mission.team.eligible,
            is_virtual: mission.volunteering.virtual,
            live_mode: mission.donation.live_mode,
            mission_rating_count: mission.volunteering.average_rating,
            seats_left: mission.volunteering.seats_left,
            goal_objective: mission.volunteering.goal_objective,
            default_media_id: mission.default_media.id,
            default_media_type: mission.default_media.type,
            default_media_path: mission.default_media.path,
            organization: {
                organization_id: mission.organization.id,
                name: mission.organization.name
            },
            mission_theme: {
                theme_name: theme,
                translations: [
                    {
                        lang,
                        title: theme
                    }
                ]
            },
            matching_gift: mission.matching_gift,
            application_deadline: mission.volunteering.deadline,
            is_donation_eligible: mission.donation.eligible,
            city_name: mission.city
                ? mission.city.name
                : null,
            donation_attribute: mission.donation.attributes,
            donation_statistics: mission.donation.statistics,
            user_application_count: mission.volunteering.is_applied,
            achieved_goal: mission.volunteering.achieved_goal,
            is_favourite: mission.is_favorite,
            skill: mission.skills ? mission.skills.map(skill => ({
                id: skill.id,
                title: skill.name
            })) : null
        }
    });
}

function buildUrl(data) {
    const legacy = localStorage.getItem('use-legacy');

    if (legacy) {
        return `${window.cipConfig.VUE_APP_API_ENDPOINT}app/missions-list?page=${data.page}`;
    }

    return `${window.cipConfig.VUE_APP_API_ENDPOINT}app/missions?page=${data.page}`;
}

function buildData(data, defaultLanguage) {
    const legacy = localStorage.getItem('use-legacy');

    if (legacy) {
        return data;
    }

    return transformData(data, defaultLanguage);
}

export default async(data) => {
    let responseData;
    let defaultLanguage = '';
    if (store.state.defaultLanguage !== null) {
        defaultLanguage = (store.state.defaultLanguage).toLowerCase();
    }
    cancel && cancel()

    let url = buildUrl(data);

    if (data.search != '' && data.search != null) {
        url = `${url}&search=${encodeURIComponent(data.search)}`;
    }

    if (data.countryId != '' && data.countryId != null) {
        url = url + "&country_id=" + data.countryId
    }
    if (data.stateId != '' && data.stateId != null) {
        url = url + "&state_id=" + data.stateId
    }
    if (data.cityId != '' && data.cityId != null) {
        url = url + "&city_id=" + data.cityId
    }
    if (data.themeId != '' && data.themeId != null) {
        url = url + "&theme_id=" + data.themeId
    }
    if (data.skillId != '' && data.skillId != null) {
        url = url + "&skill_id=" + data.skillId
    }
    if (data.type != '' && data.type != null) {
        url = url + "&type=" + data.type
    }
    if (data.type !== constants.SETTING_VOLUNTEERING) {
        url = `${url}&with_donation_attributes=1&with_donation_statistics=true`
    }
    if (data.type != '' && data.type != null && data.subTypeId != '' && data.subTypeId != null) {
        url = url + "&sub_type=" + data.subTypeId
    }
    if (data.organizationId != '' && data.organizationId != null) {
        url = url + "&organization_id=" + data.organizationId
    }

    if (data.from != '' && data.from != null && data.to != '' && data.to != null) {
        url = url + "&from=" + data.from + "&to=" + data.to
    }

    if (data.sortBy && data.sortBy != null) {
        url = url + "&sort_by=" + data.sortBy
    }

    if (data.exploreMissionType != '' && data.exploreMissionType != undefined) {
        url = url + "&explore_mission_type=" + data.exploreMissionType
    }

    if (data.exploreMissionParams != '' && data.exploreMissionParams != undefined) {
        url = url + "&explore_mission_params=" + data.exploreMissionParams
    }

    if (data.advancedFilters) {
        const parsedFilters = JSON.parse(data.advancedFilters);
        if (!isEmpty(parsedFilters)) {
            const advancedFiltersParams = `${qs.stringify({ advanced_filters: parsedFilters })}`;
            url = url + `&${advancedFiltersParams}`;
        }
    }

    url = url + "&current_view=" + data.currentView

    await axios({
        url: url,
        method: 'get',
        headers: {
            'X-localization': defaultLanguage,
        },
        cancelToken: new cancelToken(function executor (event) {
            cancel = event
        }),
    })
      .then((response) => {
          response.data.data = buildData(response.data.data, defaultLanguage)
          responseData = response.data;
          // Set filter data
            if (response.data.meta_data.filters) {
                let filterData = {};
                filterData.search = response.data.meta_data.filters.search;
                filterData.countryId = response.data.meta_data.filters.country_id;
                filterData.typeId = response.data.meta_data.filters.type ? response.data.meta_data.filters.type.toUpperCase() : response.data.meta_data.filters.type;
                filterData.subTypeId = response.data.meta_data.filters.sub_type
                    ? response.data.meta_data.filters.sub_type.split(',').map(item => item.toUpperCase()).join(',')
                    : response.data.meta_data.filters.sub_type;
                filterData.stateId = response.data.meta_data.filters.state_id;
                //todo temp fix CIP-938; redo me when filters + tags will work
                filterData.cityId = data.cityId;
                filterData.from = data.from;
                filterData.to = data.to;
                filterData.themeId = response.data.meta_data.filters.theme_id;
                filterData.organizationId = response.data.meta_data.filters.organization_id;
                filterData.skillId = response.data.meta_data.filters.skill_id;
                filterData.tags = response.data.meta_data.filters.tags;
                filterData.sortBy = response.data.meta_data.filters.sort_by;
                filterData.currentView = parseInt(response.data.meta_data.filters.current_view);
                store.commit('userFilter', filterData)
            } else {
                let filterData = {};
                filterData.search = '';
                filterData.typeId = '';
                filterData.subTypeId = '';
                filterData.countryId = '';
                filterData.stateId = '';
                filterData.cityId = '';
                filterData.from = '';
                filterData.to = '';
                filterData.organizationId = '';
                filterData.themeId = '';
                filterData.skillId = '';
                filterData.tags = '';
                filterData.sortBy = '';
                filterData.currentView = '';
                store.commit('userFilter', filterData)
            }
            if (store.state.clearFilterSet == "" && data.exploreMissionType == "") {
                document.body.classList.remove("loader-enable");
            }
        })
        .catch(function() {});
    return responseData;
}
export { cancel }
